<template>
  <el-menu
    :default-active="$route.path"
    :router="true"
    class="el-menu-vertical-demo"
    @select="onSelectMenu"
    @open="onOpenNav"
    @close="onCloseNav"
    :background-color="color.bgSide"
    text-color="#fff"
    :active-text-color="color.theme"
  >
    <el-submenu :index="item.id" v-for="(item, ind) in menus" :key="ind">
      <template slot="title">
        <i :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </template>
      <tree-sub-menu
        :menus="item.children"
        :refundNum="refundNum"
        :waitSend="waitSend"
        :waitReceived="waitReceived"
      ></tree-sub-menu>
    </el-submenu>
  </el-menu>
</template>
<script>
import TreeSubMenu from '@/components/base/TreeSubMenu.vue';
export default {
  components: {
    TreeSubMenu,
  },
  props: {
    menus: {
      type: Array,
      default: () => {
        return [
          {
            id: '0',
            name: '效果演示',
            icon: 'el-icon-message-solid',
            children: [
              {
                id: '1',
                name: 'echarts图表',
                children: [
                  {
                    id: '1',
                    name: '折线图',
                    url: '/manage/demo/echarts/line',
                  },
                  {
                    id: '2',
                    name: '饼状图',
                    url: '/manage/demo/echarts/pie',
                  },
                ],
              },
              {
                id: '2',
                name: '表单',
                children: [
                  {
                    id: '1',
                    name: '条件查询',
                    url: '/manage/demo/form/query',
                  },
                ],
              },
            ],
          },
          {
            id: '1',
            name: '店铺管理',
            icon: 'el-icon-message-solid',
            children: [
              {
                id: '1',
                name: '店铺信息',
                url: '/manage/shop/info',
              },
            ],
          },
          {
            id: '2',
            name: '数据中心',
            icon: 'el-icon-s-data',
            children: [
              {
                id: '11',
                name: '用户',
                children: [
                  {
                    id: '111',
                    name: '注册走势',
                    url: '/manage/data/user/registTrend',
                  },
                  {
                    id: '112',
                    name: '活跃度走势',
                    url: '/manage/data/user/activeTrend',
                  },
                ],
              },
            ],
          },
          {
            id: '3',
            name: '系统管理',
            icon: 'el-icon-s-management',
            children: [
              {
                id: '1',
                name: '菜单管理',
                url: '/manage/system/menu',
              },
              {
                id: '2',
                name: '权限管理',
                url: '/manage/system/auth',
              },
            ],
          },
          {
            id: '5',
            name: '平台活动',
            icon: 'el-icon-message-solid',
            children: [
              {
                id: '50',
                name: '活动列表',
                url: '/manage/active/resourceActive',
              },

              {
                id: '55',
                name: '秒杀订单',
                url: '/manage/active/flashOrder',
              },
            ],
          },
        ];
      },
    },
  },
  data() {
    return {
      refundNum: null,
      waitSend: null, //待发货
      waitReceived: null, //待收货
    };
  },
  methods: {
    onSelectMenu(path, arr) {
      let currPaths = [];
      let subMenus = [...this.menus];
      for (let i = 0; i < arr.length; i++) {
        if (i != arr.length - 1) {
          let tempPath = subMenus.filter((item) => {
            return item.id == arr[i];
          })[0];
          currPaths.push(tempPath.name);
          subMenus = tempPath.children;
        } else {
          let name = subMenus.filter((item) => {
            return item.url === path;
          })[0].name;
          currPaths.push(name);
        }
      }
      this.$store.commit('save', { path: 'currMenuPaths', data: currPaths });
    },
    onOpenNav(key, keyPath) {
      // console.log(key, keyPath);
    },
    onCloseNav(key, keyPath) {
      // console.log(key, keyPath);
    },
    //获取未审核数量
    getwaitSend() {
      this.$api.common
        .getcount({
          type: '1',
        })
        .then((res) => {
          this.waitSend = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //退款订单 待审核 2
    getrefundNum() {
      this.$api.common
        .getcount({
          type: '2',
        })
        .then((res) => {
          this.refundNum = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //全部订单 待收货 3
    getwaitReceived() {
      this.$api.common
        .getcount({
          type: '3',
        })
        .then((res) => {
          console.log(res, 'res');
          this.waitReceived = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getwaitSend();
    this.getrefundNum();
    this.getwaitReceived();

    this.$store.commit('save', { path: 'menus', data: this.menus });
  },
};
</script>
<style lang="scss" scoped>
.el-menu {
  width: 220px;
  flex-shrink: 0;
}
i {
  color: #fff;
}
</style>
