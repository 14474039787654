<template>
  <div>
    <MainHeader />
    <div id="body" class="f-fs-s">
      <TreeMenu :menus="menus" />
      <div id="main">
        <div id="router-view-box">
          <RouterView />
        </div>
        <MainFooter />
      </div>
    </div>
  </div>
</template>
<script>
import TreeMenu from "@/components/part/TreeMenu.vue";
import MainHeader from "@/components/part/MainHeader.vue";
import MainFooter from "@/components/part/MainFooter.vue";
export default {
  components: {
    TreeMenu,
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      menus: [], //递归菜单数据
      currMenuPaths: [], //当前菜单路径，例如：["系统管理","用户"]
    };
  },
  created() {
    this.getMenus();
  },
  mounted() {},
  methods: {
    getMenus() {
      this.menus = [
        {
          id: "1",
          name: "店铺管理",
          icon: "el-icon-message-solid",
          children: [
            {
              id: "11",
              name: "店铺信息",
              url: "/manage/shop/info",
            },
          ],
        },
        {
          id: "2",
          name: "商品管理",
          icon: "el-icon-message-solid",
          children: [
            {
              id: "21",
              name: "发布商品",
              url: "/manage/goods/publish",
            },
            {
              id: "12",
              name: "商品列表",
              url: "/manage/goods/list",
            },
          ],
        },
        {
          id: "3",
          name: "订单管理",
          icon: "el-icon-message-solid",
          children: [
            {
              id: "31",
              name: "全部订单",
              url: "/manage/order/arrList",
            },
            {
              id: "32",
              name: "退款订单",
              url: "/manage/order/refund",
            },
            {
              id: "33",
              name: "进货订单",
              url: "/manage/order/RestockLists",
            },
            // {
            // 暂时注释2024-11-04
            //   id: "34",
            //   name: "退货订单",
            //   url: "/manage/order/returnGoods",
            // },
          ],
        },
        {
          id: "4",
          name: "财务管理",
          icon: "el-icon-message-solid",
          children: [
            {
              id: "41",
              name: "商家财报",
              url: "/manage/finance/report",
            },
            {
              id: "42",
              name: "提现",
              url: "/manage/finance/withdraw",
            },
          ],
        },
        {
          id: "5",
          name: "平台活动",
          icon: "el-icon-message-solid",
          children: [
            {
              id: "50",
              name: "活动列表",
              url: "/manage/active/resourceActive",
            },

            {
              id: "52",
              name: "活动数据",
              url: "/manage/active/activeData",
            },
          ],
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
#body {
  height: calc(100vh - 70px);
}
#main {
  flex-grow: 1;
  overflow: auto;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #f0f2f5;
  color: #333;
}
#router-view-box {
  min-height: calc(100% - 60px);
}
</style>
